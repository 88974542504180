import { Cell, Row, Table } from "components/common/Table/Table";
import useLocale from "hooks/common/useLocale/useLocale";
import useTranslate from "hooks/common/useTranslate/useTranslate";
import { TestEnvironment } from "interfaces/test-environments";
import styles from "styles/styles";
import React from "react";
import styled from "styled-components";
import Section from "./Section";
import Status from "./Status";
import { environment as appEnvironment } from "services/common/config/config";

export const Text = styled.p`
    margin: 0;

    :not(:first-child) {
        margin-top: ${styles.spacing[4]};
    }
`;

const Container = styled.div`
    background-color: ${styles.colors.grey3};
    padding: ${styles.spacing[4]};
`;

const DetailsText = styled.p`
    font-size: ${styles.fontSize.smaller};
    margin: 0;
`;

const DetailsTable = styled(Table)`
    padding-top: ${styles.spacing[4]};
`;

const Header = styled.h4`
    font-size: ${styles.fontSize.medium};
    line-height: ${styles.fontSize.medium};
    margin: 0;
`;

const Environment = ({ environment }: { environment: TestEnvironment }) => {
    const locale = useLocale();
    const translate = useTranslate();

    const showEnvironmentDetails = appEnvironment === "local" || appEnvironment === "test"; // feature flag

    return (
        <Container>
            <Header>{environment.name[locale]}</Header>
            <Section title={translate("testEnvironments.description")}>
                <Text>{environment.description[locale]}</Text>
            </Section>
            <Section title={translate("testEnvironments.announcement")}>
                {environment.announcements[locale].length ? (
                    environment.announcements[locale].map((announcement, index) => (
                        <Text key={index}>{announcement}</Text>
                    ))
                ) : (
                    <Text>-</Text>
                )}
            </Section>

            <Section title={translate("testEnvironments.environmentStatus")}>
                {showEnvironmentDetails && environment?.apis?.length > 0 && (
                    <Table>
                        {environment.apis.map((api, index) => (
                            <Row key={index}>
                                <Cell>
                                    <Text>{api.name[locale]}</Text>
                                    {api.description && (
                                        <DetailsText>{api.description[locale]}</DetailsText>
                                    )}
                                </Cell>
                                <Cell>
                                    <Status status={api.status} />
                                </Cell>
                            </Row>
                        ))}
                    </Table>
                )}
                {environment.details && (
                    <DetailsTable>
                        {environment.details.version && (
                            <Row>
                                <Cell>
                                    <Text>{translate("testEnvironments.version")}:</Text>
                                </Cell>

                                <Cell>
                                    <Text>{environment.details.version}</Text>
                                </Cell>
                            </Row>
                        )}
                        {environment.details.schema && (
                            <Row>
                                <Cell>
                                    <Text>{translate("testEnvironments.schema")}:</Text>
                                </Cell>

                                <Cell>
                                    <Text>{environment.details.schema}</Text>
                                </Cell>
                            </Row>
                        )}
                        <>
                            {environment.details.gui && (
                                <Row>
                                    <Cell>
                                        <Text>{translate("testEnvironments.gui")}:</Text>
                                    </Cell>
                                    <Cell>
                                        <Text>{environment.details.gui}</Text>
                                    </Cell>
                                </Row>
                            )}
                            {environment.details.webClient && (
                                <Row>
                                    <Cell>
                                        <Text>{translate("testEnvironments.webClient")}:</Text>
                                    </Cell>
                                    <Cell>
                                        <Text>{environment.details.webClient}</Text>
                                    </Cell>
                                </Row>
                            )}
                        </>
                    </DetailsTable>
                )}
            </Section>
        </Container>
    );
};

export default Environment;
