import React, { useEffect } from "react";
import CustomPageContainer from "../CustomPageContainer";
import { CustomPage } from "interfaces/content";
import { getLocalizedString } from "components/utilities";
import useLocale from "hooks/common/useLocale/useLocale";
import Environment from "./Environment";
import { useDispatch, useSelector } from "react-redux";
import { fetchTestEnvironments } from "state/test-environments/actions";
import { shallowEqual } from "state/utilities";
import { RootState } from "state/reducers";
import styled from "styled-components";
import styles from "styles/styles";
import RichText from "../../RichText/RichText";
import { Col, Row } from "components/common/ui/FlexboxGrid";

const mapState = (state: RootState) => ({
    testEnvironments: state.testEnvironments.testEnvironments,
    user: state.oidc.user,
});

const ContentCol = styled(Col)`
    flex-grow: 0;

    table {
        border: 1px solid ${styles.colors.grey4};
        border-collapse: collapse;
        min-width: 100%;
        margin-bottom: ${styles.spacing[4]};
        overflow: auto;

        tr:nth-child(odd) {
            background-color: ${styles.colors.grey3};
        }

        tr:nth-child(even) {
            background-color: ${styles.colors.white};
        }

        tr:first-child {
            font-weight: bold;
            background-color: ${styles.colors.grey4};
            position: sticky;
            position: 0;
        }

        tr {
            th,
            td {
                padding: 0.2em 0.5em;
                margin: 0;
                text-align: left;
                border-bottom: 1px solid ${styles.colors.grey4};

                p {
                    margin: 0;
                    padding: 0;
                }

                :last-child {
                    width: 100%;
                    white-space: normal;
                }
            }
        }

        tr:last-child td {
            border-bottom: none;
        }

        tbody tr:first-child {
            position: sticky;
            top: 0;
            z-index: 1;
        }
    }
`;

const EnvironmentCol = styled(Col)`
    margin-bottom: ${styles.spacing[4]};
`;

const TestEnvironments: React.FC<{
    customPage: CustomPage;
}> = ({ customPage }) => {
    const locale = useLocale();
    const { testEnvironments, user } = useSelector(mapState, shallowEqual);
    const dispatch = useDispatch();
    const authenticated = !!user;

    useEffect(() => {
        dispatch(fetchTestEnvironments.started({ authenticated }));
    }, [dispatch, authenticated]);

    const pageDescription = (() => {
        if (customPage.authorizedContent?.[locale]) {
            return customPage.authorizedContent;
        }

        return customPage?.pageDesc;
    })();

    return (
        <CustomPageContainer tabs={[]}>
            <h3>{getLocalizedString(locale, customPage.pageHeader)}</h3>
            <Row>
                <ContentCol sm={12}>
                    {pageDescription?.[locale] && <RichText richText={pageDescription} />}
                </ContentCol>
            </Row>
            <Row>
                {testEnvironments.map((environment, idx) => (
                    <EnvironmentCol key={idx} sm={12} md={6}>
                        <Environment environment={environment} />
                    </EnvironmentCol>
                ))}
            </Row>
        </CustomPageContainer>
    );
};

export default TestEnvironments;
