import React from "react";

import { BlogPost as BlogPostType } from "interfaces/content";
import useLocale from "hooks/common/useLocale/useLocale";
import { BLOG_ROUTE } from "services/common/url/url";
import { formatDate } from "services/common/dates/dates";
import RichText from "components/content/RichText/RichText";
import RichTextPreview from "components/content/RichTextPreview/RichTextPreview";
import styled, { css } from "styled-components";
import styles from "styles/styles";
import { Circle } from "../../../screens/blog/BlogPage/BlogPage";

interface Props {
    blogPost: BlogPostType;
    previewMode?: boolean;
}

export const Title = styled.h4`
    margin: 0;
    font-size: ${styles.fontSize.medium};
    padding-bottom: ${styles.spacing[1]};
`;

export const SubTitle = styled.div`
    color: ${styles.colors.grey7};
    font-size: ${styles.fontSize.normal};
`;

export const MainTitle = styled.h3`
    margin: 0;
    padding-bottom: ${styles.spacing[2]};
`;

const SubTitleDate = styled.span``;

const previewStyle = css`
    padding-bottom: ${styles.spacing[4]};
    border-bottom: 1px solid ${styles.colors.grey4};
`;

const PreviewContainer = styled.div<{ previewMode?: boolean }>`
    ${(props) => props.previewMode && previewStyle};
`;

const BlogPost = ({ blogPost, previewMode }: Props) => {
    const locale = useLocale();

    const formattedDate = formatDate(blogPost.publishingDate, locale);

    return (
        <PreviewContainer previewMode={previewMode}>
            {previewMode ? (
                <Title>{blogPost.title[locale]}</Title>
            ) : (
                <MainTitle>{blogPost.title[locale]}</MainTitle>
            )}

            <SubTitle>
                {blogPost.author}
                <Circle />
                <SubTitleDate>{formattedDate}</SubTitleDate>
            </SubTitle>
            {previewMode ? (
                <RichTextPreview
                    richText={blogPost.content}
                    readMoreLink={`/${locale}/${BLOG_ROUTE}/${blogPost.id}`}
                    maxCharLength={250}
                    readMoreIntlKey="blog.readMore"
                />
            ) : (
                <RichText richText={blogPost.content} />
            )}
        </PreviewContainer>
    );
};

export default BlogPost;
