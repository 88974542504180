import Link from "components/common/ui/Link/Link";
import useLocale from "hooks/common/useLocale/useLocale";
import useTranslate from "hooks/common/useTranslate/useTranslate";
import externalLinkIcon from "images/external-link.svg";
import { LocalizedString } from "interfaces/common";
import { LinkButton as LinkButtonType } from "interfaces/content";
import { isExternalUrl } from "services/common/link/link";
import { Locale } from "services/common/localization/localization";
import styles from "styles/styles";
import React, { ReactElement } from "react";
import styled from "styled-components";
import { buttonStyles } from "components/common/ui/buttons";

interface Props {
    linkButton: LinkButtonType;
}

const LinkContentContainer = styled.div`
    color: ${styles.colors.white};
    background-color: ${styles.colors.red};
    display: inline-flex;
    align-items: center;
    padding: ${styles.spacing[2]} ${styles.spacing[4]};
    font-weight: 500;
    ${buttonStyles.primary}
`;

const Label = styled.div`
    padding-right: ${styles.spacing[2]};
`;

const Icon = styled.img`
    width: 20px;
    height: 20px;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
`;

const LinkContent = ({
    linkText,
    hasIcon,
    locale,
    iconAlt,
}: {
    linkText: LocalizedString;
    locale: Locale;
    hasIcon?: boolean;
    iconAlt?: string;
}) => (
    <LinkContentContainer>
        <Label>{linkText[locale]}</Label>
        {hasIcon && <Icon src={externalLinkIcon} alt={iconAlt} />}
    </LinkContentContainer>
);

function LinkButton({ linkButton }: Props): ReactElement {
    const locale = useLocale();
    const translate = useTranslate();
    const isExternalLink = isExternalUrl(linkButton.url[locale] || "");

    return (
        <StyledLink localizedUrl={linkButton.url} openExternalLinkToNewTab={true}>
            <LinkContent
                linkText={linkButton.label}
                locale={locale}
                hasIcon={isExternalLink ? true : undefined}
                iconAlt={isExternalLink ? translate("common.externalLink") : undefined}
            />
        </StyledLink>
    );
}

export default LinkButton;
