import useLocale from "hooks/common/useLocale/useLocale";
import React, { ReactElement } from "react";
import { Helmet } from "react-helmet";

interface Props {
    description: string;
}

const MetaDescription = ({ description }: Props): ReactElement => {
    const locale = useLocale();

    return (
        <Helmet htmlAttributes={{ lang: locale }}>
            <meta name="description" content={description} />
        </Helmet>
    );
};

export default MetaDescription;
