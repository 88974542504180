import React, { ReactElement } from "react";
import { Location } from "history";
import { match, useLocation, useRouteMatch } from "react-router-dom";

import useLocale from "hooks/common/useLocale/useLocale";
import { NavigationTab, SubNavigationTab } from "interfaces/navigation";
import styled, { css } from "styled-components";
import styles from "styles/styles";
import NavLinkAdapter from "../NavLinkAdapter/NavLinkAdapter";
import { compact } from "lodash";

export const tabSorter = (
    tab: NavigationTab | SubNavigationTab,
    other: NavigationTab | SubNavigationTab
) => {
    if (tab.sortIndex && other.sortIndex) {
        return other.sortIndex - tab.sortIndex;
    }

    return 0;
};

interface Props {
    tab: NavigationTab;
    isDefaultTab?: boolean;
    active?: boolean;
}

const activeLinkStyle = css`
    color: ${styles.colors.red};
    border-left-color: ${styles.colors.red};
    font-weight: bold;
`;

const StyledSubNavLink = styled(NavLinkAdapter)`
    &__className {
        padding-left: ${styles.spacing[2]};
        display: block;
        text-decoration: none;
        color: ${styles.colors.grey7};
        margin: ${styles.spacing[2]} 0;
        border-left: 5px solid transparent;
        font-size: 12pt;
        font-weight: 400;

        :hover {
            border-left-color: ${styles.colors.grey6};
            text-decoration: none;
            color: ${styles.colors.grey7};
        }
    }
    &__activeClassName {
        ${activeLinkStyle}
        :hover {
            ${activeLinkStyle}
        }
    }
`;

const StyledNavLink = styled(NavLinkAdapter)`
    &__className {
        padding-left: ${styles.spacing[2]};
        display: block;
        text-decoration: none;
        color: ${styles.colors.grey7};
        margin: ${styles.spacing[2]} 0;
        border-left: 5px solid transparent;

        :hover {
            border-left-color: ${styles.colors.grey6};
            text-decoration: none;
            color: ${styles.colors.grey7};
        }
    }
    &__activeClassName {
        ${activeLinkStyle}
        :hover {
            ${activeLinkStyle}
        }
    }
`;

const getAriaCurrent = (isActive: boolean) => (isActive ? "location" : undefined);

const isActive =
    (tabPath: string, isDefaultTab: boolean, override?: boolean) =>
    (match: match | null, location: Location) => {
        if (override !== undefined) {
            return override;
        }

        // NavHashLink doesn't match hash fragments, hence the custom isActive logic.
        if (!match) {
            return false;
        }

        if (isDefaultTab && !location.hash && !location.search) {
            // Mark default tab as active when no fragment hash or query is defined but paths match
            return tabPath.split("#")[0] === location.pathname;
        }

        return tabPath === `${location.pathname}${location.search}${location.hash}`;
    };

const SectionNavTab = ({ tab, isDefaultTab, active }: Props): ReactElement => {
    const locale = useLocale();
    const match = useRouteMatch();
    const location = useLocation();

    const isMainTabActiveFn = isActive(tab.path, !!isDefaultTab, active);
    const isMainTabActive = isMainTabActiveFn(match, location);

    const isSubTabActive = tab.subTabs.reduce(
        (acc, subTab) => (acc ? acc : isActive(subTab.path, !!isDefaultTab)(match, location)),
        false
    );

    const sortedSubTabs =
        compact(tab.subTabs?.map((subtab) => subtab.sortIndex)).length > 0
            ? tab.subTabs.sort(tabSorter)
            : tab.subTabs;

    return (
        <StyledNavLink
            key={tab.path}
            isActive={isMainTabActiveFn}
            to={tab.path}
            aria-current={`${isMainTabActive}`}
        >
            <span>{tab.label[locale]}</span>
            {(isMainTabActive || isSubTabActive) &&
                sortedSubTabs.map((subTab) => (
                    <StyledSubNavLink
                        key={subTab.path}
                        isActive={isActive(subTab.path, !!isDefaultTab)}
                        to={subTab.path}
                        aria-current={getAriaCurrent(
                            isActive(subTab.path, !!isDefaultTab)(match, location)
                        )}
                    >
                        <span>{subTab.label[locale]}</span>
                    </StyledSubNavLink>
                ))}
        </StyledNavLink>
    );
};

export default SectionNavTab;
