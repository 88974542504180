import { Locale } from "interfaces/urls.d";
export interface AnalyticsWindowContext extends Window {
    plausible?: any;
}

interface AnalyticsEvent {
    name: string;
    props: unknown;
}

const windowContext: AnalyticsWindowContext = window;

const trackEvent = async (event: AnalyticsEvent) => {
    try {
        if (windowContext?.plausible) {
            windowContext.plausible(event.name, {
                props: event.props,
            });
        }
    } catch {
        /* NOP */
    }
};

export interface FileDownloadEventProps {
    url?: string;
    filename?: string;
    locale: Locale;
}

export const trackFileDownload = async ({ url, filename, locale }: FileDownloadEventProps) =>
    trackEvent({
        name: "File Download",
        props: {
            url,
            filename,
            locale,
        },
    });

export interface MediaEventProps {
    type: "video" | "audio";
    source: string;
    locale: Locale;
}
export const trackMedia = async ({ type, source, locale }: MediaEventProps) =>
    trackEvent({
        name: "Media View",
        props: {
            type,
            source,
            locale,
        },
    });

export interface CTAEventProps {
    label: string;
    url: string;
    locale: Locale;
}
export const trackCTA = async ({ label, url, locale }: CTAEventProps) =>
    trackEvent({
        name: "CTA Link",
        props: {
            label,
            url,
            locale,
        },
    });

export interface QueryEventProps {
    query: string;
    locale?: Locale;
}
export const trackSearch = async ({ query, locale }: QueryEventProps) =>
    trackEvent({
        name: "Site Search",
        props: {
            query,
            locale,
        },
    });

export interface QuestionnaireEventProps {
    resultUrl: string;
    locale: Locale;
}
export const trackCertificationQuestionnaireCompleted = async ({
    resultUrl,
    locale,
}: QuestionnaireEventProps) =>
    trackEvent({
        name: "Certification Questionnaire Completed",
        props: {
            resultUrl,
            locale,
        },
    });
