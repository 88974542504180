import React from "react";

import { Tag } from "interfaces/content";
import useLocale from "hooks/common/useLocale/useLocale";
import styled from "styled-components";
import styles from "styles/styles";

interface Props {
    tag: Tag;
    isSelected: boolean;
    onClick: (tag: Tag) => void;
}

const Container = styled.button`
    background-color: ${styles.colors.white};
    padding: ${styles.spacing[3]};
    border: 2px solid ${styles.colors.white};
    color: ${styles.colors.grey7};
    overflow: hidden;
    hyphens: auto;
    width: 100%;
    word-wrap: break-word;
    font-size: ${styles.fontSize.small};
    height: 100%;

    :focus {
        outline-color: ${styles.colors.focusBorderColor};
    }

    :hover > img {
        filter: saturate(1) hue-rotate(1deg);
    }

    @media (hover: hover) {
        /* Applies only to devices that use hover. e.g. desktop & laptops */
        :hover {
            cursor: pointer;
            border-color: ${styles.colors.red};
            color: ${styles.colors.red};
        }
    }
`;

const ActiveContainer = styled(Container)`
    border-color: ${styles.colors.red};
    color: ${styles.colors.red};

    > img {
        filter: saturate(1) hue-rotate(1deg);
    }
`;

const TagIcon = styled.img`
    display: block;
    margin: ${styles.spacing[1]} auto;
    width: 45px;
    filter: grayscale(100%);
`;

const FileSearchTag = ({ tag, isSelected, onClick }: Props) => {
    const locale = useLocale();

    const Button = isSelected ? ActiveContainer : Container;

    return (
        <Button
            data-testid="file-search-tag-button"
            onClick={() => onClick(tag)}
            aria-pressed={isSelected ? "true" : "false"}
        >
            {tag.icon && tag.icon.file[locale] && (
                <TagIcon
                    src={tag.icon.file[locale]!.url}
                    alt={tag.icon.description ? tag.icon.description[locale] : ""}
                />
            )}

            <span>{tag.label[locale]}</span>
        </Button>
    );
};

export default FileSearchTag;
