import React, { ReactElement, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Redirect, Route, Switch } from "react-router-dom";
import PageLoadingIndicator from "components/common/ui/PageLoadingIndicator/PageLoadingIndicator";
import useLocale from "hooks/common/useLocale/useLocale";
import { NavigationTab } from "interfaces/navigation";
import DynamicContentPage from "screens/common/DynamicContentPage/DynamicContentPage";
import FrontPage from "screens/common/FrontPage/FrontPage";
import NewsFeedItemPage from "screens/news/NewsFeedItemPage/NewsFeedItemPage";
import SearchPage from "screens/search/SearchPage/SearchPage";
import BlogPage from "screens/blog/BlogPage/BlogPage";
import BlogPostPage from "screens/blog/BlogPostPage/BlogPostPage";
import Auth from "screens/common/Auth/Auth";
import LoginFailed from "screens/common/LoginFailed/LoginFailed";
import {
    BLOG_URL_SEGMENT,
    NEWS_URL_SEGMENT,
    SEARCH_URL_SEGMENT,
    AUTH_URL_SEGMENT,
    LOGIN_FAILED_URL_SEGMENT,
    FAULT_NOTIFICATIONS_CONTACT_URL_SEGMENT,
    BLOG_ROUTE,
} from "services/common/url/url";
import { Locale, LOCALES } from "services/common/localization/localization";
import PageNotFound from "../../ui/PageNotFound/PageNotFound";
import FaultNotificationContact from "screens/fault-notification-contact/FaultNotificationContact";
import CookiePolicyPage from "components/common/CookiePolicy/CookiePolicy";

interface Props {
    pageTabs: NavigationTab[] | null;
    isLoading: boolean;
}

const rootPath = `/:locale(${LOCALES.join("|")})`;

const renderPageTabRoutes = (pageTabs: NavigationTab[], locale: Locale): ReactElement[] =>
    pageTabs.reduce((allRoutes: ReactElement[], pageTab) => {
        const nestedSubRoutes = pageTab.subTabs.reduce((routes: ReactElement[], subTab) => {
            const subTabRoute = (
                <Route key={pageTab.path} exact path={`${rootPath}${subTab.path}`}>
                    <DynamicContentPage />
                </Route>
            );

            const redirect = (
                <Route key={subTab.path} exact path={subTab.path}>
                    <Redirect to={`/${locale}${subTab.path}`} />
                </Route>
            );

            return [...routes, subTabRoute, redirect];
        }, []);

        return [...allRoutes, ...nestedSubRoutes];
    }, []);

function RouteContent({ isLoading, pageTabs }: Props): ReactElement {
    const locale = useLocale();

    const pageTabRoutes = useMemo(
        () => (pageTabs ? renderPageTabRoutes(pageTabs, locale) : null),
        [locale, pageTabs]
    );

    if (isLoading) {
        return <PageLoadingIndicator isLoading={isLoading} />;
    }

    return (
        <Switch>
            {/* @TODO add predefined routes & lazy load their components. */}
            <Route exact path={rootPath}>
                <FrontPage />
            </Route>

            <Route exact path="/">
                <Redirect to={`/${locale}`} />
            </Route>

            <Route exact path={`${rootPath}/${NEWS_URL_SEGMENT}/:id`}>
                <NewsFeedItemPage />
            </Route>

            <Route exact path={`${rootPath}/cookie-policy`}>
                <CookiePolicyPage />
            </Route>

            <Route exact path={`${rootPath}/${FAULT_NOTIFICATIONS_CONTACT_URL_SEGMENT}`}>
                <FaultNotificationContact />
            </Route>

            {
                // -- LEGACY -- for COMPATIBILITY
            }
            <Route exact path={`${rootPath}/uutiset-ja-tapahtumat/${BLOG_URL_SEGMENT}`}>
                <BlogPage />
            </Route>

            <Route exact path={`${rootPath}/uutiset-ja-tapahtumat/${BLOG_URL_SEGMENT}/:id`}>
                <BlogPostPage />
            </Route>
            {
                // -- END LEGACY --
            }

            <Route exact path={`${rootPath}/${BLOG_ROUTE}`}>
                <BlogPage />
            </Route>

            <Route exact path={`${rootPath}/${BLOG_ROUTE}/:id`}>
                <BlogPostPage />
            </Route>

            <Route exact path={`${rootPath}/${SEARCH_URL_SEGMENT}`}>
                <SearchPage />
            </Route>

            <Route exact path={`/${AUTH_URL_SEGMENT}`}>
                <Auth />
            </Route>

            <Route exact path={`${rootPath}/${LOGIN_FAILED_URL_SEGMENT}`}>
                <LoginFailed />
            </Route>

            {pageTabRoutes}

            {/* Catch all route */}
            <Route>
                <PageNotFound
                    message={<FormattedMessage id="navigation.pageNotFound" />}
                    returnUrl={`/${locale}`}
                />
            </Route>
        </Switch>
    );
}

export default RouteContent;
