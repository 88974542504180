import React, { useRef } from "react";
import { MediaContentYoutubeItem } from "interfaces/content";
import useElementDimensions from "hooks/common/useElementDimensions/useElementDimensions";
import styled from "styled-components";
import { trackMedia } from "services/common/analytics/analytics";
import useLocale from "hooks/common/useLocale/useLocale";

interface Props {
    item: MediaContentYoutubeItem;
}

const aspectRatio = 16 / 9;

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const MediaContentYoutube = ({ item }: Props) => {
    const ref = useRef(null);
    const locale = useLocale();
    const { width } = useElementDimensions(ref);

    return (
        <Container
            ref={ref}
            onClick={() => trackMedia({ type: "video", source: item.youtubeUrl, locale })}
        >
            <iframe
                style={{ border: "none" }}
                width={width}
                height={width / aspectRatio}
                src={item.youtubeUrl}
                allowFullScreen
            ></iframe>
        </Container>
    );
};

export default MediaContentYoutube;
