import React, { ReactElement } from "react";
import { ExternalLink } from "interfaces/content";
import { FormattedMessage } from "react-intl";
import useLocale from "hooks/common/useLocale/useLocale";
import useTranslate from "hooks/common/useTranslate/useTranslate";
import externalLinkIcon from "images/external-link-grey.svg";
import styled from "styled-components";
import styles from "styles/styles";

interface Props {
    externalLinks: ExternalLink[];
}

const ExternalLinkContainer = styled.nav`
    background-color: transparent;
    box-shadow: none;
    position: sticky;
`;

const Title = styled.h4`
    margin: 0;
    padding-bottom: ${styles.spacing[2]};
    font-size: ${styles.fontSize.large};
    color: ${styles.colors.grey7};
`;

const Icon = styled.img`
    width: 16px;
    height: 16px;
    margin-left: ${styles.spacing[2]};
`;

const Link = styled.a`
    font-size: ${styles.fontSize.small};
    line-height: 1.5;
    color: ${styles.colors.red};
    text-decoration: underline;
    padding-bottom: ${styles.spacing[1]};
    border-bottom: 1px solid ${styles.colors.grey3};
    margin: ${styles.spacing[2]} 0;
    display: block;
`;

const ExternalLinksList = ({ externalLinks }: Props): ReactElement => {
    const locale = useLocale();
    const translate = useTranslate();

    return (
        <ExternalLinkContainer>
            <Title>
                <FormattedMessage id="sideContent.externalLinks" />{" "}
                <Icon src={externalLinkIcon} alt={translate("common.externalLink")} />
            </Title>
            {externalLinks.map((externalLink) => (
                <Link
                    key={externalLink.url[locale]}
                    href={externalLink.url[locale]}
                    rel="noreferrer"
                    target="_blank"
                >
                    {externalLink.label[locale]}
                </Link>
            ))}
        </ExternalLinkContainer>
    );
};

export default ExternalLinksList;
