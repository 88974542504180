import styled from "styled-components";
import isInteger from "lodash/isInteger";
import config, { DIMENSION_NAMES } from "./config";
import React from "react";

interface ColProps {
    reverse?: boolean;
    children?: React.ReactNode;

    [key: string]: any; // This is to allow for dynamic props like xs, sm, md, lg, etc.
}

const offsetProps = DIMENSION_NAMES.map((d) => d + "Offset");

const Col = styled.div<ColProps>`
    max-width: 100%;
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: ${(p) => config(p).gutterWidth / 2}rem;
    padding-left: ${(p) => config(p).gutterWidth / 2}rem;

    ${(p) =>
        p.reverse &&
        `
            flex-direction: column-reverse;
        `}
    ${(p) =>
        Object.keys(p)
            .filter((k) => DIMENSION_NAMES.includes(k))
            .sort((k1, k2) => DIMENSION_NAMES.indexOf(k1) - DIMENSION_NAMES.indexOf(k2))
            .map(
                (k) => config(p).media[k]`
                    ${
                        isInteger(p[k])
                            ? `
                              flex-basis: ${(100 / config(p).gridSize) * p[k]}%;
                              max-width: ${(100 / config(p).gridSize) * p[k]}%;
                              display: block;
                          `
                            : p[k]
                            ? `
                              flex-grow: 1;
                              flex-basis: 0;
                              max-width: 100%;
                              display: block;
                          `
                            : `
                              display: none;
                          `
                    }
                `
            )}

    ${(p) =>
        Object.keys(p)
            .filter((k) => offsetProps.includes(k))
            .map(
                (k) => config(p).media[k.replace(/Offset$/, "")]`

                    margin-left: ${(100 / config(p).gridSize) * p[k]}%;
                `
            )}
`;

Col.displayName = "Col";

export default Col;
