import React from "react";
import useLocale from "hooks/common/useLocale/useLocale";
import { MediaContentTextItem } from "interfaces/content";
import styled from "styled-components";
import styles from "styles/styles";
import RichText from "../RichText/RichText";

interface Props {
    item: MediaContentTextItem;
}

const MediaContentHeader = styled.h4`
    margin-left: 16px;
`;

const TextContent = styled.div<{ isHalfContainer?: boolean; isHalfImage?: boolean }>`
    margin: 0;
    margin-left: 16px;
    padding-bottom: ${styles.spacing[2]};
    font-weight: normal;

    p {
        line-height: 1.5;
        font-size: 20px;
    }
`;

const MediaContentText = ({ item }: Props) => {
    const locale = useLocale();

    return (
        <div>
            <MediaContentHeader>{item.title?.[locale]}</MediaContentHeader>
            <TextContent>
                {item.description && <RichText richText={item.description} />}
            </TextContent>
        </div>
    );
};

export default MediaContentText;
