import { isExternalUrl } from "services/common/link/link";
import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import styles from "styles/styles";
import { trackFileDownload } from "services/common/analytics/analytics";
import useLocale from "hooks/common/useLocale/useLocale";

interface Props {
    label?: string;
    src: string;
    href: string;
    openInNewTab?: boolean;
    download?: boolean;
}

const linkStyle = css`
    font-weight: 500;
    display: flex;
    align-items: center;
    color: ${styles.colors.grey7};
    text-decoration: none;
`;

const NativeLink = styled.a`
    ${linkStyle}
`;

const RouterLink = styled(Link)`
    ${linkStyle}
`;

const getTargetProps = (openInNewTab: boolean) =>
    openInNewTab ? { target: "_blank", rel: "noopener noreferrer" } : {};

interface ContainerProps {
    src: string;
    label?: string;
}

const Text = styled.p`
    padding-left: 5px;
`;

const IconContent = ({ src, label }: ContainerProps) => (
    <>
        <img src={src} />
        {label && <Text>{label}</Text>}
    </>
);

const IconLink = ({ label, src, href, openInNewTab, download }: Props) => {
    const locale = useLocale();

    return isExternalUrl(href) || openInNewTab || download ? (
        <NativeLink
            href={href}
            {...getTargetProps(!!openInNewTab)}
            download={!!download}
            onClick={() => {
                if (download) {
                    trackFileDownload({
                        url: href,
                        locale,
                    });
                }
            }}
        >
            <IconContent src={src} label={label} />
        </NativeLink>
    ) : (
        <RouterLink
            to={href}
            onClick={() => {
                if (download) {
                    trackFileDownload({
                        url: href,
                        locale,
                    });
                }
            }}
        >
            <IconContent src={src} label={label} />
        </RouterLink>
    );
};
export default IconLink;
