import { compact } from "lodash";
import React from "react";

export interface SectionNavContextType {
    activeItems: string[];
    setVisible: (item: string) => void;
    setInvisible: (item: string) => void;
    containsSegment: (segmentId: string) => boolean;
}

export const SectionNavContext = React.createContext<SectionNavContextType>({
    activeItems: [],
    setVisible: () => {},
    setInvisible: () => {},
    containsSegment: () => false,
});

export const GROUP_DELIM = "#";

const SectionNavProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [activeItems, setActiveItems] = React.useState<string[]>([]);

    const setVisible = React.useCallback((segmentId: string) => {
        setActiveItems((items) => compact([...items, segmentId]));
    }, []);

    const setInvisible = React.useCallback((segmentId: string) => {
        setActiveItems((items) => items.filter((v) => v !== segmentId));
    }, []);

    const containsSegment = React.useCallback(
        (segmentId: string) => {
            if (segmentId.includes(GROUP_DELIM)) {
                return activeItems.includes(segmentId);
            }

            return activeItems.some((item) => item.includes(segmentId));
        },
        [activeItems]
    );

    const items = React.useMemo(() => activeItems, [activeItems]);

    return (
        <SectionNavContext.Provider
            value={{ activeItems: items, containsSegment, setVisible, setInvisible }}
        >
            {children}
        </SectionNavContext.Provider>
    );
};

export default SectionNavProvider;
