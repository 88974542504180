import { NavigationTab } from "interfaces/navigation";
import React from "react";
import SectionNav from "../SectionNav/SectionNav";
import styled from "styled-components";
import styles from "styles/styles";
import Page from "components/common/layout/Page";
import SectionNavProvider from "../SectionNav/SectionNavContext";
import { useLocation } from "react-router";

interface Props {
    tabs: NavigationTab[];
    children?: React.ReactNode;
}

const Container = styled.div`
    display: flex;
    max-width: ${styles.layout.contentMaxWidth};
    margin: ${styles.layout.contentMargin};
    padding: ${styles.layout.contentPadding};
`;

const Tabs = styled.div`
    background-color: ${styles.colors.lightGrey};
    padding: 20px 0;
    display: inline-flex;
    flex: 1;
`;

const Content = styled.div`
    background-color: white;
    padding: ${styles.spacing[2]} 0 ${styles.spacing[6]} ${styles.spacing[4]};
    flex: 4;
`;

const CustomPageContainer: React.FC<Props> = (props) => {
    const location = useLocation();
    const locationHashRef = React.useRef<string>(location.hash);
    const scrolledToLocationRef = React.useRef<boolean>(false);

    React.useEffect(() => {
        if (location.hash) {
            if (locationHashRef.current !== location.hash) {
                locationHashRef.current = location.hash;
                scrolledToLocationRef.current = false;
            }

            if (!scrolledToLocationRef.current) {
                const id = location.hash.replace("#", "");
                const element = document.getElementById(id);

                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                    scrolledToLocationRef.current = true;
                }
            }
        }
    });

    return !!props.tabs.length ? (
        <SectionNavProvider>
            <Container>
                <Tabs>
                    <SectionNav tabs={props.tabs} />
                </Tabs>
                <Content>{props.children}</Content>
            </Container>
        </SectionNavProvider>
    ) : (
        <Page>{props.children}</Page>
    );
};

export default CustomPageContainer;
