import React, { ReactElement, useMemo } from "react";
import { useLocation } from "react-router-dom";

import {
    ContentfulDocumentEmbeddedContent,
    DocumentContentBlock,
    Section as SectionType,
    SegmentedDocument,
    SubPage,
} from "interfaces/content";
import { LocalizedString } from "interfaces/common";
import { NavigationTab } from "interfaces/navigation";
import useLocale from "hooks/common/useLocale/useLocale";
import SectionNav from "../SectionNav/SectionNav";
import RichText, { DocumentEmbeddedEntryContentBlock, isSection } from "../RichText/RichText";
import ExternalLinksList from "./ExternalLinksList/ExternalLinksList";
import styled from "styled-components";
import styles from "styles/styles";
import SectionNavProvider from "../SectionNav/SectionNavContext";

export interface Props {
    subPage: SubPage;
    title?: LocalizedString;
}

type Indexed<T> = T & { index: number };

function getEmbeddedIndexedSections(
    content: Indexed<DocumentContentBlock>[]
): Indexed<SectionType>[] {
    return content.reduce(
        (
            sections: Indexed<SectionType>[],
            contentBlock: Indexed<DocumentEmbeddedEntryContentBlock>
        ) => {
            const isEmbeddedEntry = contentBlock.nodeType === "embedded-entry-block";

            const embeddedEntry: Indexed<ContentfulDocumentEmbeddedContent> | undefined =
                contentBlock.data.target
                    ? {
                          ...contentBlock.data.target,
                          index: contentBlock.index,
                      }
                    : undefined;

            if (isEmbeddedEntry && embeddedEntry && isSection(embeddedEntry)) {
                return sections.concat(embeddedEntry);
            }

            return [...sections];
        },
        []
    );
}

function getSectionsTabs(
    sections: Indexed<SectionType>[],
    pathname: string
): Indexed<NavigationTab>[] {
    return sections.map((section, idx) => {
        const distanceToNextAnchor =
            idx < sections.length - 1 ? sections[idx + 1].index - section.index : undefined;

        return {
            path: `${pathname}#${section.urlSegment}`,
            label: section.title,
            urlSegment: section.urlSegment,
            index: section.index,
            distanceToNextAnchor,
            subTabs: [],
        };
    });
}

const Tabs = styled.div`
    background-color: ${styles.colors.lightGrey};
    padding: 20px;
    display: inline-flex;
    flex: 1;
`;

const Content = styled.div`
    background-color: white;
    padding: ${styles.spacing[2]} 0 ${styles.spacing[6]} ${styles.spacing[4]};
    flex: 4;
`;

const StickyContainer = styled.div`
    position: sticky;
    top: 0;
    width: 100%;
    max-height: 700px;
`;

const Divider = styled.div`
    background-color: #d5dde3;
    position: sticky;
    height: 1px;
    margin: ${styles.spacing[4]} 0;
    width: 100%;
`;

function RichTextContainer({ subPage, title }: Props): ReactElement | null {
    const locale = useLocale();
    const location = useLocation();
    const locationHashRef = React.useRef<string>(location.hash);
    const scrolledToLocationRef = React.useRef<boolean>(false);

    const indexedContent: Indexed<DocumentContentBlock>[] | undefined = useMemo(() => {
        const document = subPage.authorizedContent?.[locale] || subPage.content?.[locale];

        return document?.content.map((contentBlock, index) => ({ ...contentBlock, index }));
    }, [subPage, locale]);

    const sectionsTabs = useMemo(() => {
        if (!indexedContent) {
            return undefined;
        }
        const indexedSections = getEmbeddedIndexedSections(indexedContent);

        return getSectionsTabs(indexedSections, location.pathname);
    }, [location, indexedContent]);

    const hasSectionTabs = useMemo(() => !!sectionsTabs && sectionsTabs.length > 0, [sectionsTabs]);
    const segmentedContent = React.useMemo((): SegmentedDocument | undefined => {
        const localizedSubpageContent =
            subPage.authorizedContent?.[locale] || subPage.content?.[locale];

        if (!indexedContent || !sectionsTabs || !localizedSubpageContent) {
            return undefined;
        }

        const sortedSectionTabs = sectionsTabs.slice().sort((a, b) => b.index - a.index);
        const segmentedBlocks = indexedContent.map((contentBlock) => {
            const idx = contentBlock.index;

            const nearestPreviousSection = sortedSectionTabs.find((tab) => {
                if (idx >= tab.index) {
                    return tab;
                }

                return undefined;
            });

            const block = {
                ...contentBlock,
                segmentId: nearestPreviousSection
                    ? `${nearestPreviousSection?.urlSegment}`
                    : undefined,
            };

            return block;
        });

        return {
            ...localizedSubpageContent,
            content: segmentedBlocks,
        };
    }, [locale, subPage.content, subPage.authorizedContent, indexedContent, sectionsTabs]);

    const hasSideCol = useMemo(
        () => hasSectionTabs || !!subPage.externalLinks,
        [hasSectionTabs, subPage.externalLinks]
    );

    React.useEffect(() => {
        if (location.hash) {
            if (locationHashRef.current !== location.hash) {
                locationHashRef.current = location.hash;
                scrolledToLocationRef.current = false;
            }

            if (!scrolledToLocationRef.current) {
                const id = location.hash.replace("#", "");
                const element = document.getElementById(id);

                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                    scrolledToLocationRef.current = true;
                }
            }
        }
    });

    return (
        <SectionNavProvider>
            {hasSideCol && (
                <Tabs>
                    <StickyContainer>
                        {hasSectionTabs && <SectionNav followScroll={true} tabs={sectionsTabs!} />}
                        {subPage.externalLinks && hasSectionTabs && <Divider />}
                        {subPage.externalLinks && (
                            <ExternalLinksList externalLinks={subPage.externalLinks} />
                        )}
                    </StickyContainer>
                </Tabs>
            )}
            <Content>
                {title && <h3 id={`subpage#header#${subPage.id}`}>{title[locale]}</h3>}
                {indexedContent && (
                    <RichText
                        id={`subpage#content#${subPage.id}`}
                        localizedSegmentedContent={segmentedContent}
                        updateSectionNavContext={true}
                    />
                )}
            </Content>
        </SectionNavProvider>
    );
}

export default RichTextContainer;
