import useLocale from "hooks/common/useLocale/useLocale";
import { HeroCard as HeroCardType, LinkButton } from "interfaces/content";
import styles from "styles/styles";
import objectFitImages from "object-fit-images";
import React, { ReactElement } from "react";
import styled, { css } from "styled-components";
import RichText from "../RichText/RichText";
import HeroCardCountdown from "../HeroCardCountdown/HeroCardCountdown";
import HeroCardLink from "../HeroCardLink/HeroCardLink";
import { Col, Row } from "../../common/ui/FlexboxGrid";

type ContentType = "frontpage" | "dynamic";

export interface Props {
    heroCard: HeroCardType;
    isHalfContainer?: boolean;
    isHalfImage?: boolean;
    contentIsLeftAligned?: boolean;
    type: ContentType;
}

const getLinkButtonGroups = (linkButtons: LinkButton[]) => {
    const a: LinkButton[] = [],
        b: LinkButton[] = [];

    linkButtons.forEach((linkButton, index) => {
        if (index % 2 === 0) {
            a.push(linkButton);
        } else {
            b.push(linkButton);
        }
    });

    return [a, b];
};

const Title = styled.h2`
    font-size: 46px;
    text-align: left;
`;

const LinkButtons = styled(Row)`
    margin: 0;
`;

export const Scrim = styled.div`
    background: rgb(0 0 0 / 60%);
    width: 100%;
    height: 100%;
    max-height: 680px;
    min-height: 480px;
    position: absolute;
`;

const halfContentHeader = css`
    color: ${styles.colors.grey8};
    margin: 0 0 0 16px;
    padding-bottom: ${styles.spacing[5]};
`;

const dynamicTitleStyle = css`
    color: ${styles.colors.white};
    margin-left: 16px;
`;

const TitleContainer = styled.div<{
    isHalfContainer: boolean;
    isHalfImage: boolean;
    type: ContentType;
}>`
    display: flex;
    align-items: flex-start;

    @media only screen and (${styles.breakpoints.smGridBreakpoint}) {
        flex-direction: column;
    }

    > h2 {
        ${(props) => props.type === "dynamic" && dynamicTitleStyle}

        margin: 0;
        padding-bottom: ${styles.spacing[2]};
    }

    ${(props) => props.isHalfContainer && props.isHalfImage && halfContentHeader}
`;

export const HeroImg = styled.img`
    width: 100%;
    height: 100%;
    max-height: 680px;
    min-height: 480px;
    object-fit: cover;
    /* object-fit-images library special syntax to make object-fit work on IE11 */
    font-family: "object-fit: cover;";
`;

const ImageContainer = styled.div<{ isHalfImage: boolean }>`
    ${(props) => props.isHalfImage && `max-width: 50%`}
`;

const StyledHeroCardCountdown = styled(HeroCardCountdown)`
    margin-left: ${styles.spacing[4]};
    margin-top: -21px;

    @media only screen and (${styles.breakpoints.smGridBreakpoint}) {
        margin-top: 0;
    }
`;

const Content = styled.div<{ isHalfContainer?: boolean; isHalfImage?: boolean }>`
    margin-left: auto;
    margin-right: auto;
    max-width: ${(props) => (props.isHalfContainer ? "600px" : "1200px")};
`;

const ContentContainer = styled.div<{ contentIsLeftAligned?: boolean; isHalfImage?: boolean }>`
    position: absolute;
    width: 100%;
    top: 25%;
    z-index: 2;
    max-height: 100%;

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        margin: ${styles.layout.contentPadding};
    }

    @media only screen and (max-width: 1100px) {
        top: 15%;
    }

    > h2 {
        color: ${styles.colors.white};
        margin: 0;
        margin-left: 16px;
        padding-bottom: ${styles.spacing[2]};
    }
`;

const SubTitle = styled.div<{ isHalfContainer?: boolean; isHalfImage?: boolean }>`
    margin: 0;
    margin-left: 16px;
    padding-bottom: ${styles.spacing[2]};
    font-weight: normal;

    ${(props) => props.isHalfContainer && props.isHalfImage && halfContentHeader}

    p {
        line-height: 1.5;
        font-size: 20px;
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;

const HalfImageContainer = css`
    position: static;
    align-self: center;
`;

const RightAlign = css`
    right: 0;
    margin-left: 20px;

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        right: auto;
    }
`;

const LeftAlign = css`
    left: 0;
    margin-right: 20px;

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        left: auto;
    }
`;

export const HalfContentContainer = styled.div<{
    contentIsLeftAligned?: boolean;
    isHalfImage?: boolean;
}>`
    position: absolute;
    width: 100%;
    top: 25%;
    z-index: 2;
    max-height: 100%;
    max-width: calc(${styles.layout.contentMaxWidth} / 2);

    ${(props) => props.isHalfImage && HalfImageContainer};
    ${(props) => (props.contentIsLeftAligned ? LeftAlign : RightAlign)};

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        max-width: 90%;
    }
`;

const halfImageContainer = css`
    display: flex;
    justify-content: center;
`;

export const HeroContainer = styled.div<{ isHalfImage?: boolean }>`
    width: 100%;
    position: relative;
    height: 100%;
    overflow: hidden;

    ${(props) => props.isHalfImage && halfImageContainer};
`;

function HeroCard({
    heroCard,
    isHalfContainer,
    isHalfImage,
    contentIsLeftAligned,
    type = "dynamic",
}: Props): ReactElement {
    const locale = useLocale();
    objectFitImages(null); // Needed to allow object-fit to work on IE11

    if (!heroCard.media) {
        return <div />;
    }

    const Container = isHalfContainer ? HalfContentContainer : ContentContainer;

    return (
        <HeroContainer isHalfImage={isHalfImage}>
            <Container contentIsLeftAligned={!!contentIsLeftAligned} isHalfImage={!!isHalfImage}>
                <Content isHalfContainer={isHalfContainer} isHalfImage={isHalfImage}>
                    <TitleContainer
                        isHalfContainer={!!isHalfContainer}
                        isHalfImage={!!isHalfImage}
                        type={type}
                    >
                        <Title>{heroCard.title && heroCard.title[locale]}</Title>
                        {heroCard.countdown && (
                            <StyledHeroCardCountdown date={new Date(heroCard.countdown)} />
                        )}
                    </TitleContainer>
                    <SubTitle>
                        {heroCard.subTitle && <RichText richText={heroCard.subTitle} />}
                    </SubTitle>
                    {heroCard.linkButtons && (
                        <LinkButtons>
                            {getLinkButtonGroups(heroCard.linkButtons).map((group, index) => (
                                <Col xs={12} md={6} key={index}>
                                    <div>
                                        {group.map((linkButton, buttonIndex) => (
                                            <HeroCardLink
                                                key={`${linkButton.url}${buttonIndex}`}
                                                linkButton={linkButton}
                                                isEven={index % 2 === 0}
                                            />
                                        ))}
                                    </div>
                                </Col>
                            ))}
                        </LinkButtons>
                    )}
                </Content>
            </Container>
            {!isHalfImage && <Scrim />}
            {heroCard.media && heroCard.media.file[locale] && (
                <ImageContainer isHalfImage={!!isHalfImage}>
                    <HeroImg
                        src={`${heroCard.media.file[locale]!.url}?fm=jpg&fl=progressive`}
                        alt={`${
                            heroCard.media.description ? heroCard.media.description[locale] : ""
                        }`}
                    />
                </ImageContainer>
            )}
        </HeroContainer>
    );
}

export default HeroCard;
