import React, { useEffect, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { fetchLandingPage } from "state/content/contentful";
import { shallowEqual } from "state/utilities";
import { RootState } from "state/reducers";
import useDocumentTitle from "hooks/common/navigation/useDocumentTitle";
import MetaDescription from "components/common/MetaDescription/MetaDescription";
import HeroCard from "components/content/HeroCard/HeroCard";
import { translate } from "components/utilities";
import LinkCardList from "components/content/LinkCardList/LinkCardList";
import { fetchEvents } from "state/events";
import { loadingSelectorFactory } from "state/common/loading";
import { sortAscending } from "components/content/CustomPageContent/EventsAndWebinars/EventsAndWebinars";
import FrontPageList from "components/frontpage/FrontPageList";
import useLocale from "hooks/common/useLocale/useLocale";
import { ListItemData } from "components/frontpage/ListItem";
import { formatDate, formatDateWithHour } from "services/common/dates/dates";
import { fetchNewsFeed } from "state/news";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { NewsFeedItem } from "interfaces/content";
import { NEWS_URL_SEGMENT } from "services/common/url/url";
import MediaContent from "components/content/MediaContent/MediaContent";
import Page from "components/common/layout/Page";
import ColumnRow from "../../../components/content/ColumnRow/ColumnRow";
import { Col, Row } from "../../../components/common/ui/FlexboxGrid";

const loadingSelectorEvents = loadingSelectorFactory([fetchEvents]);
const loadingSelectorNews = loadingSelectorFactory([fetchNewsFeed]);

export const sortNewsFeedItems = (newsFeedItems: NewsFeedItem[]) =>
    newsFeedItems
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        .sort((a, b) => (b.isActive ? 1 : a.isActive ? -1 : 0));

const mapState = (state: RootState) => ({
    landingPage: state.content.contentful.landingPage,
    isLoadingEvents: loadingSelectorEvents(state),
    events: state.events.events
        .filter((event) => !event.isPastEvent)
        .sort(sortAscending)
        .slice(0, 3),
    isLoadingNews: loadingSelectorNews(state),
    newsFeedItems: sortNewsFeedItems(state.news.newsFeed?.newsFeedItems || []).slice(0, 3),
});

const getNewsDescription = (newsFeedItem: NewsFeedItem, locale: string): string => {
    const content =
        locale === "fi"
            ? newsFeedItem.content.fi?.content?.[0]
            : newsFeedItem.content.en?.content?.[0];

    return content ? documentToPlainTextString(content) : "";
};

const columnStyle = `
    background-color: #fff;
    text-align: center;
`;

const FrontPage = (): ReactElement => {
    const locale = useLocale();
    const { landingPage, isLoadingEvents, events, isLoadingNews, newsFeedItems } = useSelector(
        mapState,
        shallowEqual
    );
    const dispatch = useDispatch();
    const intl = useIntl();
    useDocumentTitle(translate(intl, "frontPage.title"));

    useEffect(() => {
        dispatch(fetchLandingPage.started());
        dispatch(fetchEvents.started());
        dispatch(fetchNewsFeed.started({}));
    }, [dispatch]);

    if (!landingPage) {
        return <div />;
    }

    const frontPageDescription = translate(intl, "frontPage.description");
    const defaultCompanyName = "Fingrid Datahub Oy"; // Used when no companyName is defined.

    // console.log(landingPage.heroCard);

    return (
        <>
            <MetaDescription description={frontPageDescription} />
            <div>
                {landingPage.heroCard && (
                    <HeroCard
                        heroCard={landingPage.heroCard}
                        isHalfContainer={true}
                        isHalfImage={true}
                        contentIsLeftAligned={true}
                        type="frontpage"
                    />
                )}
                {landingPage.linkCards && landingPage.linkCards.length > 0 && (
                    <LinkCardList linkCards={landingPage.linkCards} />
                )}
                <Page>
                    <Row>
                        <Col xs={12} md={6}>
                            <FrontPageList
                                title={translate(intl, "frontPage.upcomingEvents")}
                                showAllLabel={translate(intl, "frontPage.showAllEvents")}
                                showAllUrl={`/${locale}/uutiset-ja-tapahtumat/tapahtumat-ja-webinaarit`}
                                isLoading={isLoadingEvents}
                                listItems={events.map<ListItemData>((event) => ({
                                    details: [formatDate(event.date, locale), event.location]
                                        .filter(Boolean)
                                        .join(" "),
                                    description: event.description[locale] || "",
                                    url: event.weblink || "",
                                    backgroundColor: "light",
                                }))}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <FrontPageList
                                title={translate(intl, "frontPage.latestNews")}
                                showAllLabel={translate(intl, "frontPage.showAllNews")}
                                showAllUrl={`/${locale}/uutiset-ja-tapahtumat/uutiset`}
                                isLoading={isLoadingNews}
                                listItems={newsFeedItems.map<ListItemData>((newsFeedItem) => ({
                                    details: `${newsFeedItem.companyName || defaultCompanyName} | 
                                    ${formatDateWithHour(newsFeedItem.publishingDate, locale)}`,
                                    description: getNewsDescription(newsFeedItem, locale),
                                    url: `/${locale}/${NEWS_URL_SEGMENT}/${newsFeedItem.id}`,
                                    backgroundColor: "light",
                                }))}
                            />
                        </Col>
                    </Row>
                </Page>
                {landingPage.mediaContent &&
                    landingPage.mediaContent.length > 0 &&
                    landingPage.mediaContent.map((mediaContent, i) => (
                        <MediaContent key={i} mediaContent={mediaContent} />
                    ))}
                {landingPage.columnRows &&
                    landingPage.columnRows.length > 0 &&
                    landingPage.columnRows.map((columnRow, i) => (
                        <ColumnRow key={i} columnStyle={columnStyle} columnRow={columnRow} />
                    ))}
            </div>
        </>
    );
};

export default FrontPage;
