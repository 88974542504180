import * as React from "react";
import searchIcon from "../../../../images/search-grey.svg";
import TextInput from "../TextInput/TextInput";
import styled from "styled-components";
import styles from "../../../../styles/styles";

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
    value: string;
    onChange: (input: string) => void;
    ariaLabel?: string;
}

const SearchWrapper = styled.div`
    display: flex;
    position: relative;
`;

const StyledTextInput = styled(TextInput)`
    border-color: ${styles.colors.grey4};
    padding: 0 30px 0 8px;
    height: 60px;

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        margin: 0 10px 10px 0;
    }
`;

const StyledSearchIcon = styled.img`
    position: absolute;
    width: auto;
    height: 50%;
    top: 25%;
    right: 20px;
    max-width: 100%;
    max-height: 100%;
    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        margin-top: 20px;
    }
`;

const Search = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;

const SearchInput = ({ value, onChange, ariaLabel, ...standardProps }: Props) => (
    <SearchWrapper>
        <Search>
            <StyledSearchIcon src={searchIcon} alt="" />
            <StyledTextInput
                value={value}
                onChange={onChange}
                ariaLabel={ariaLabel}
                {...standardProps}
            />
        </Search>
    </SearchWrapper>
);

export default SearchInput;
