import React, { ReactElement } from "react";

import useTranslate from "hooks/common/useTranslate/useTranslate";
import { NavigationTab } from "interfaces/navigation";
import SectionNavTab, { tabSorter } from "../SectionNavTab/SectionNavTab";
import styled from "styled-components";
import { compact } from "lodash";
import { SectionNavContext } from "./SectionNavContext";

interface Props {
    tabs: NavigationTab[];
    followScroll?: boolean;
}

const SectionNavContainer = styled.nav`
    background-color: transparent;
    box-shadow: none;
    position: sticky;
    max-height: 700px;
`;

function SectionNav({ tabs, followScroll }: Props): ReactElement {
    const translate = useTranslate();
    const { activeItems, containsSegment } = React.useContext(SectionNavContext);

    const sortedTabs =
        compact(tabs.map((tab) => tab.sortIndex)).length > 0 ? tabs.sort(tabSorter) : tabs;

    return (
        <SectionNavContainer aria-label={translate("navigation.pageNavbar")}>
            {sortedTabs.map((tab, index) => (
                <SectionNavTab
                    active={
                        followScroll
                            ? activeItems.length > 0 && containsSegment(tab.urlSegment)
                            : undefined
                    }
                    key={`${tab.path}`}
                    tab={tab}
                    isDefaultTab={index === 0}
                />
            ))}
        </SectionNavContainer>
    );
}

export default SectionNav;
