import React, { useCallback, useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { Tag } from "interfaces/content";
import useLocale from "hooks/common/useLocale/useLocale";
import useTranslate from "hooks/common/useTranslate/useTranslate";
import styled, { css } from "styled-components";
import styles from "styles/styles";
import Dropdown from "../../../common/ui/Dropdown/Dropdown";
import Arrow from "../../../common/ui/Arrow/Arrow";
import SearchInput from "../../../common/ui/SearchInput/SearchInput";
import { Row } from "../../../common/ui/FlexboxGrid";

interface Props {
    searchTerm: string;
    allTags: Tag[];
    selectedTagId: string;
    onSearchTermChange: (searchTerm: string) => void;
    onTagChange: (tagId: string) => void;
}

const dropdownWidth = "200px";
const dropdownHeight = "40px";

export const DropdownHeaderContainer = styled(Row)`
    background-color: ${styles.colors.grey3};
    padding: ${styles.spacing[1]} ${styles.spacing[2]};
    width: ${dropdownWidth};
    min-height: ${dropdownHeight};
    margin: 0;
    font-size: ${styles.fontSize.small};
    color: ${styles.colors.grey7};
    text-align: left;
`;

const TagFilterLabel = styled.div`
    max-width: calc(100% - ${styles.spacing[6]});
    margin-top: ${styles.spacing[1]};
    text-align: left;
`;

const listItemBtn = css`
    background: transparent;
    border: none;
    width: 100%;
    padding: ${styles.spacing[2]};
    text-align: left;

    :hover {
        cursor: pointer;
        background-color: ${styles.colors.grey3};
    }
`;

const selectedListItemBtn = css`
    background: transparent;
    border: none;
    text-align: left;
    width: 100%;
    padding: ${styles.spacing[2]};
    color: ${styles.colors.red};
    font-weight: bold;

    :hover {
        cursor: pointer;
        text-decoration: underline;

        span:after {
            float: right;
            content: "\\2715";
        }
    }
`;

export const TagFilterButton = styled.button<{ isSelected: boolean }>`
    ${(props) => (props.isSelected ? selectedListItemBtn : listItemBtn)}
`;

const FilterButton = ({
    label,
    isSelected,
    onClick,
}: {
    label: string | undefined;
    isSelected: boolean;
    onClick: () => void;
}) => (
    <TagFilterButton isSelected={isSelected} onClick={onClick}>
        <span>{label}</span>
    </TagFilterButton>
);

const SearchInputContainer = styled.div`
    padding: 0 ${styles.spacing[2]} 0 0;
    input {
        height: ${dropdownHeight};
        margin: 0;
    }

    img {
        margin-top: ${styles.spacing[2]};
    }

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        padding-bottom: ${styles.spacing[2]};
    }
`;

const Container = styled(Row)`
    margin: 0;
    padding-bottom: ${styles.spacing[2]};
`;

const DropdownContentContainer = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

/**
 * To forward contentClassName
 */
const DropdownAdapter = ({
    className,
    ...props
}: { className?: string } & React.ComponentProps<typeof Dropdown>) => (
    <Dropdown {...props} contentClassName={`${className}__contentClassName`} />
);

const StyledDropdown = styled(DropdownAdapter)`
    &__contentClassName {
        padding: 0;
        min-width: ${dropdownWidth};
    }
`;

const NewsFeedFilters = ({
    searchTerm: currentSearchTerm,
    onSearchTermChange,
    allTags,
    selectedTagId,
    onTagChange,
}: Props) => {
    const locale = useLocale();
    const translate = useTranslate();
    const [isOpen, setOpen] = useState(false);
    // global search-term state is not updated immediately due to the debounce, therefore a local state is necessary.
    const [searchTerm, setSearchTerm] = useState("");

    const onChange = (newSearchTerm: string) => {
        setSearchTerm(newSearchTerm);
        onSearchTermChange(newSearchTerm);
    };

    // If the search term or tag filter are given as prop changes, set the local state to that.
    // This also inits the local state.
    useEffect(() => {
        setSearchTerm(currentSearchTerm);
    }, [currentSearchTerm]);

    const toggle = useCallback(() => {
        setOpen((isOpen) => !isOpen);
    }, []);

    const tagFilter = allTags.find((tag) => tag.id === selectedTagId);

    const renderHeader = useCallback(
        () => (
            <DropdownHeaderContainer between="xs" middle="xs">
                <TagFilterLabel>
                    {tagFilter ? (
                        <strong>{tagFilter.label[locale]}</strong>
                    ) : (
                        <FormattedMessage id="newsfeed.filterNews" />
                    )}
                </TagFilterLabel>
                <Arrow direction="down" />
            </DropdownHeaderContainer>
        ),
        [tagFilter, locale]
    );

    const onTagClick = useCallback(
        (selectedTag: Tag) => {
            const newTagId = selectedTagId === selectedTag.id ? "" : selectedTag.id;

            onTagChange(newTagId);
            toggle();
        },
        [selectedTagId, onTagChange, toggle]
    );
    const renderContent = useCallback(
        () => (
            <DropdownContentContainer>
                {allTags &&
                    allTags
                        .sort((a, b) =>
                            a.id === tagFilter?.id ? -1 : b.id === tagFilter?.id ? 1 : 0
                        )
                        .map((tag) => (
                            <li key={tag.id}>
                                <FilterButton
                                    label={tag.label[locale]}
                                    isSelected={!!tagFilter && tag.id === tagFilter.id}
                                    onClick={() => onTagClick(tag)}
                                />
                            </li>
                        ))}
            </DropdownContentContainer>
        ),
        [locale, allTags, onTagClick, tagFilter]
    );

    return (
        <Container end="md" start="xs" middle="xs">
            <SearchInputContainer>
                <SearchInput
                    value={searchTerm}
                    onChange={onChange}
                    ariaLabel={translate("common.search")}
                />
            </SearchInputContainer>
            <StyledDropdown
                renderButtonContent={renderHeader}
                renderContent={renderContent}
                isOpen={isOpen}
                toggle={toggle}
            />
        </Container>
    );
};

export default NewsFeedFilters;
