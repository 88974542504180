import { NavigationTab } from "interfaces/navigation.d";
import {
    BlogUrlSegment,
    NewsUrlSegment,
    SearchUrlSegment,
    AuthUrlSegment,
    LoginFailedUrlSegment,
} from "interfaces/urls.d";
import { isValidLocale } from "services/common/localization/localization";

/**
 * @desc getUrlSegment Fn finds a url-segment from a pathname based on an index.
 */
export function getUrlSegment(pathname: string, index: UrlSegmentIndex): string {
    return pathname.split("/")[index];
}

export function getLocale(pathname: string) {
    const locale = getUrlSegment(pathname, UrlSegmentIndex.Locale);

    return isValidLocale(locale) ? locale : null;
}

export enum UrlSegmentIndex {
    Locale = 1,
    Page = 2,
    SubPage = 3,
    Section = 4,
}

/**
 * Remove the locale from the given pathname.
 * E.g. /fi/datahub/page -> /datahub/page
 */
export function getPathnameWithoutLocale(pathname: string) {
    return `/${pathname.split("/").slice(UrlSegmentIndex.Page).join("/")}`;
}

export const isValidPageUrl = (pageUrl: string, navigationTabs: NavigationTab[] | null) => {
    const isAllowedUrl = !NOT_ALLOWED_URLS.includes(pageUrl);

    if (!isAllowedUrl || !navigationTabs) {
        return false;
    }

    return navigationTabs.some((tab) => tab.urlSegment === pageUrl);
};

export const BLOG_URL_SEGMENT: BlogUrlSegment = "blogi";
export const NEWS_URL_SEGMENT: NewsUrlSegment = "uutisvirta";
export const FAULT_NOTIFICATIONS_CONTACT_URL_SEGMENT = "hairioviestien-yhteystiedot";
export const SEARCH_URL_SEGMENT: SearchUrlSegment = "haku";
export const AUTH_URL_SEGMENT: AuthUrlSegment = "auth";
export const LOGIN_FAILED_URL_SEGMENT: LoginFailedUrlSegment = "kirjautuminen-epäonnistui";

export const BLOG_ROUTE = `blogit/${BLOG_URL_SEGMENT}`;

// List of reserved url segments that can't be added dynamically through Contentful.
// This is necessary due to router's route configs that contain pre-defined routes.
export const NOT_ALLOWED_URLS: string[] = [
    BLOG_URL_SEGMENT,
    NEWS_URL_SEGMENT,
    SEARCH_URL_SEGMENT,
    AUTH_URL_SEGMENT,
    LOGIN_FAILED_URL_SEGMENT,
];

export const BLOG_PAGE_NUM_QUERY_PARAM = "page";

export const SEARCH_PAGE_NUM_QUERY_PARAM = "page";
export const SEARCH_SEARCH_TERM_QUERY_PARAM = "q";
