import React, { ReactElement } from "react";
import styled from "styled-components";
import { Section as SectionType } from "interfaces/content";
import useLocale from "hooks/common/useLocale/useLocale";
import RichText from "../RichText/RichText";

const SectionHeader = styled.h3``;

interface Props {
    section: SectionType;
    "data-segment-id"?: string;
}

function Section({ section, ...props }: Props): ReactElement {
    const locale = useLocale();

    return (
        <>
            <SectionHeader data-segment-id={props["data-segment-id"]} id={section.urlSegment}>
                {section.title[locale]}
            </SectionHeader>
            <RichText
                data-segment-id={props["data-segment-id"]}
                richText={section.content}
            ></RichText>
        </>
    );
}

export default Section;
