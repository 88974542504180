import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import Page from "../layout/Page";
import styled from "styled-components";

const CookieDeclarationContainer = styled.iframe`
    border: none;
    width: 100%;
`;

const CookieDeclaration = () => {
    const ref = useRef<HTMLIFrameElement>(null);
    const [height, setHeight] = useState("100px");

    const onLoad = () => {
        if (!ref.current || !ref.current.contentWindow) {
            return;
        }

        setHeight(ref.current.contentWindow.document.body.scrollHeight + 30 + "px");
    };

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <CookieDeclarationContainer
            ref={ref}
            onLoad={onLoad}
            height={height}
            src="/cookie-declaration.html"
            sandbox="allow-scripts allow-same-origin"
        ></CookieDeclarationContainer>
    );
};

const CookiePolicyPage = () => (
    <Page>
        <h3>
            <FormattedMessage id="navigation.cookies" />
        </h3>
        <CookieDeclaration />
    </Page>
);

export default CookiePolicyPage;
